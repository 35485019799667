import React, { memo } from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import SimpleReactLightbox, { SRLWrapper } from "simple-react-lightbox"
import Layout from "../components/layout"
import SEO from "../components/seo"
import ConversationIcon from "../images/conversation.inline.svg"
import EngineeringIcon from "../images/engineering.inline.svg"
import UrbanIcon from "../images/urban.inline.svg"

const IndexPage = () => {
  const data = useStaticQuery(graphql`
    query {
      allFile(
        filter: {
          extension: { regex: "/(jpg)|(jpeg)|(png)/" }
          dir: { regex: "/projects/" }
        }
      ) {
        edges {
          node {
            id
            base
            relativeDirectory
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
                originalImg
              }
            }
          }
        }
      }
    }
  `)

  const options = {
    settings: {
      overlayColor: "rgba( 255, 255, 255, .9 )",
      autoplaySpeed: 1500,
      transitionSpeed: 900,
    },
    buttons: {
      backgroundColor: "rgba( 0, 0, 0, .4 )",
      iconColor: "#fff",
      showDownloadButton: false,
      showAutoplayButton: false,
      showThumbnailsButton: false,
      showFullscreenButton: false,
    },
    caption: {
      showCaption: false,
    },
  }

  const Aschabat = data.allFile.edges.filter(
    image => image.node.relativeDirectory === "projects/Aschabat"
  )
  const CiernyMost = data.allFile.edges.filter(
    image => image.node.relativeDirectory === "projects/CiernyMost"
  )
  const drozdy = data.allFile.edges.filter(
    image => image.node.relativeDirectory === "projects/drozdy"
  )
  const Fittich = data.allFile.edges.filter(
    image => image.node.relativeDirectory === "projects/Fittich"
  )
  const kimex = data.allFile.edges.filter(
    image => image.node.relativeDirectory === "projects/kimex"
  )
  const NBU = data.allFile.edges.filter(
    image => image.node.relativeDirectory === "projects/NBU"
  )
  const ObytnyKomplex = data.allFile.edges.filter(
    image => image.node.relativeDirectory === "projects/ObytnyKomplex"
  )
  const Telekom = data.allFile.edges.filter(
    image => image.node.relativeDirectory === "projects/Telekom"
  )
  const VSZ = data.allFile.edges.filter(
    image => image.node.relativeDirectory === "projects/VSZ"
  )
  const Zafir = data.allFile.edges.filter(
    image => image.node.relativeDirectory === "projects/Zafir"
  )
  const FolklornyFestival = data.allFile.edges.filter(
    image => image.node.relativeDirectory === "projects/FolklornyFestival"
  )
  const PesiaZonaPresov = data.allFile.edges.filter(
    image => image.node.relativeDirectory === "projects/PesiaZonaPresov"
  )
  const PesiaZonaPresovPrvky = data.allFile.edges.filter(
    image => image.node.relativeDirectory === "projects/PesiaZonaPresovPrvky"
  )

  const ProjectSection = memo(({ title, description, data }) => (
    <section className="bg-white shadow mt-10 px-4 md:px-10 lg:px-32 py-10">
      <h2 className="text-center text-xl md:text-2xl font-semibold">{title}</h2>
      <p className="text-center md:text-lg pb-6">{description}</p>
      <SRLWrapper options={options}>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8 md:gap-10">
          {data.map(image => (
            <div key={image.node.id} className="shadow w-full max-w-md mx-auto">
              <a
                href={image.node.childImageSharp.fluid.originalImg}
                data-attribute="SRL"
              >
                <Img
                  className="w-full h-full fit-contain"
                  fluid={image.node.childImageSharp.fluid}
                  alt={image.node.base.split(".")[0]} // only use section of the file extension with the filename
                />
              </a>
            </div>
          ))}
        </div>
      </SRLWrapper>
    </section>
  ))

  return (
    <Layout>
      <SimpleReactLightbox>
        <SEO title="Home" />

        <section id="home" className="pt-10">
          <h1 className="bg-white bg-opacity-70 p-6 md:p-12 shadow text-3xl md:text-5xl lg:text-7xl text-center font-light tracking-wide">
            <span className="font-semibold">ARCH</span>KOMPLET
            <br />
            s.r.o.
          </h1>
        </section>

        <section
          id="about"
          className="bg-white p-4 md:p-12 shadow mt-10 grid grid-cols-1 md:grid-cols-3 gap-9"
        >
          <div>
            <div className="flex justify-center pb-4">
              <UrbanIcon className="h-16 w-16" />
            </div>
            <h2 className="text-lg md:text-xl font-semibold mb-2 text-center">
              Územné plány miest a obcí
            </h2>
            <h2 className="text-lg md:text-xl font-semibold mb-2 text-center">
              Územné plány zón
            </h2>
            <h2 className="text-lg md:text-xl font-semibold mb-2 text-center">
              Urbanistické štúdie
            </h2>
          </div>
          <div>
            <div className="flex justify-center pb-4">
              <EngineeringIcon className="h-16 w-16" />
            </div>
            <h2 className="text-lg md:text-xl font-semibold mb-2 text-center">
              Komplexná projektová príprava stavieb
            </h2>
            <p className="pb-1 text-center">
              novostavby, rekonštrukcie, interiéry
            </p>
            <p>
              Administratívne budovy, obchodné stavby, firemné stavby – areály,
              výrobné a priemyselné areály, prevádzkove budovy, objekty pre
              šport a rekreáciu, obytné stavby – bytové domy, rodinné domy,
              chaty
            </p>
          </div>
          <div>
            <div className="flex justify-center pb-4">
              <ConversationIcon className="h-16 w-16" />
            </div>
            <h2 className="text-lg md:text-xl font-semibold mb-2 text-center">
              Poradenská a konzultačná činnosť
            </h2>
            <p className="text-center">
              inžiniering a činnosť generálneho projektanta stavby
            </p>
          </div>
        </section>

        <article id="projects" className="pb-12">
          <ProjectSection
            data={Fittich}
            title="Fittich Preal s.r.o."
            description="Administratívno-prevádzková budova"
          />
          <ProjectSection
            data={PesiaZonaPresov}
            title="Pešia zóna"
            description="Prešov - Hlavná ulica"
          />
          <ProjectSection
            data={PesiaZonaPresovPrvky}
            title="pešia zóna - prvky drobnej architektúry"
            description="Prešov"
          />
          <ProjectSection
            data={Aschabat}
            title="Prezidentská rezidencia Ašchabat"
            description="Turkménska republika"
          />
          <ProjectSection
            data={NBU}
            title="Národná banka Ukrajiny"
            description="Užhorod"
          />
          <ProjectSection
            data={kimex}
            title="Spoločenské priestory, Kimex s.r.o."
            description="Poštová 6, Košice"
          />
          <ProjectSection
            data={FolklornyFestival}
            title="Areál amfiteátra folklórneho festivalu"
            description="Východná"
          />
          <ProjectSection
            data={Zafir}
            title="Predajňa Zafír fy Čedo"
            description="Prešov, Hlavná 107"
          />
          <ProjectSection
            data={VSZ}
            title="Obchodno-propagačné centrum VSŽ a.s."
            description="PKošice, Alžbetina 21,23"
          />
          <ProjectSection
            data={Telekom}
            title="Rekonštrukcia a dostavba prevádzkovej budovy Slovenských Telekomunikácií"
            description="Masarykova 1, Prešov"
          />
          <ProjectSection
            data={ObytnyKomplex}
            title="Obytný komplex"
            description="Švermova 3553, Prešov"
          />
          <ProjectSection
            data={CiernyMost}
            title="Reštaurácia Čierny most"
            description="rekonštrukcia a dostavba"
          />
          <ProjectSection
            data={drozdy}
            title="Obytný súbor Drozdy"
            description="Minsk"
          />
        </article>

        <hr />

        <section
          id="contact"
          className="grid grid-cols-1 md:grid-cols-2 pt-16 pb-12"
        >
          <div className="flex items-center flex-col">
            <img
              src="/sergej.jpg"
              alt="Ing. arch. Sergej Pastorok"
              className="w-32 h-32"
            />
            <p className="pt-4 pb-6">Ing. arch. Sergej Pastorok</p>
          </div>

          <div className="flex items-center flex-col">
            <img
              src="/janka.jpg"
              alt="Ing. arch. Jana Pastorokova"
              className="w-32 h-32"
            />
            <p className="pt-4 pb-6">Ing. arch. Jana Pastorokova</p>
          </div>
        </section>
      </SimpleReactLightbox>
    </Layout>
  )
}

export default IndexPage
